.mdc-text-field
    @apply h-fit min-h-[2.5rem] min-w-[8rem] p-0 #{!important}

.mat-mdc-form-field-infix
    @apply w-fit min-h-fit p-2 #{!important}

.mat-mdc-form-field
    @apply w-full #{!important}

//prefix icon
.mat-mdc-form-field-icon-prefix
    @apply px-2 #{!important}

.mat-mdc-form-field.mat-mdc-form-field-has-icon-prefix .mat-mdc-form-field-infix
    @apply pl-0 #{!important}


//border-radius
.mdc-text-field
    --mdc-outlined-text-field-container-shape: 0.5rem !important

//border colors
.mdc-text-field .mdc-notched-outline__leading,
.mdc-text-field .mdc-notched-outline__notch,
.mdc-text-field .mdc-notched-outline__trailing
    @apply border-newNeutral4 #{!important}

.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__trailing
    @apply border-newPrimaryLight #{!important}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input
    @apply caret-newText #{!important}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input
    @apply caret-newText #{!important}

.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing
    @apply border-red-200 border-2 #{!important}

//placeholder
.mdc-text-field .mdc-text-field__input::placeholder
    @apply relative text-primarySecondary body-1 z-10 #{!important}

//disabled text
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input
    @apply text-newTextLight z-10 relative

//disabled bg
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__trailing
    @apply bg-newNeutral2 #{!important}

//hint & error msg
.noHintError .mat-mdc-form-field-subscript-wrapper
    @apply hidden #{!important}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper
    @apply p-0 #{!important}

.mat-mdc-form-field-subscript-wrapper
    &.mat-mdc-form-field-bottom-align
        @apply leading-3 h-0

.mat-mdc-form-field-error
    @apply text-red-200

.mat-mdc-form-field-hint
    @apply text-newTextLight caption-1

//suffix icon
.mat-mdc-form-field.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix
    @apply px-2 static #{!important}

.mat-mdc-form-field.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-infix
    @apply pr-0 #{!important}
