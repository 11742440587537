.mdc-switch__track
    @apply h-5 w-10 min-w-[40px] rounded-full border-0 #{!important}

.mdc-switch:enabled .mdc-switch__track::before
    @apply bg-primarySecondary border-0 #{!important}

.mdc-switch:enabled .mdc-switch__track::after
    @apply bg-secondaryMain border-0 #{!important}

.mdc-switch__handle
    @apply ml-0.5

.mdc-switch__handle-track
    @apply w-5 translate-x-1 #{!important}

.mdc-switch--selected
    .mdc-switch__handle-track
        @apply translate-x-[100%] #{!important}

.mdc-switch__handle, .mdc-switch__handle::before, .mdc-switch__handle::after
    @apply w-4 h-4 bg-white #{!important}

.mdc-switch__shadow, .mdc-switch__ripple, .mdc-switch__icons
    @apply hidden #{!important}
