//mat-option state bg colors
html
    --mat-option-hover-state-layer-color: var(--background)!important
    --mat-option-focus-state-layer-color: var(--background)!important
    --mat-option-selected-state-layer-color: var(--background)!important

.mat-mdc-select
    @apply w-fit text-newText flex #{!important}

.mat-mdc-select-panel
    @apply p-2 mt-1 rounded-lg shadow-md max-h-[22rem] #{!important}

    &::-webkit-scrollbar
     @apply bg-newNeutral1 px-2 rounded

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover
        @apply bg-newNeutral3 rounded

.mat-mdc-option
    @apply p-0 m-0 min-h-[16px] rounded box-content leading-4 #{!important}

.mat-mdc-option span
    @apply p-2 m-0 block rounded box-content leading-4 #{!important}
.mat-mdc-option .mat-icon
    @apply mr-2 #{!important}
.mat-mdc-option:not(.mdc-list-item--disabled)
    &:hover
        @apply bg-newNeutral3 #{!important}

.mat-mdc-select .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    @apply body-1 #{!important}

.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper
    @apply border-newTextLight

//placeholder
.mat-mdc-select-placeholder
    @apply relative text-newTextLight body-1 z-10 #{!important}

//select + options text
.mat-mdc-select,
.mat-mdc-option
    @apply relative text-newText body-1 z-10 #{!important}

//select custom icon
.mat-mdc-select-arrow
    @apply invisible #{!important}

.mat-mdc-form-field-flex
    @apply relative #{!important}

.mat-mdc-form-field-icon-suffix
    @apply absolute right-0 #{!important}

.mat-mdc-form-field-icon-suffix>.mat-icon.matSelectIconCustom
    @apply h-5 w-6 py-0 pr-3 pl-2 text-newTextLight text-3xl leading-5 box-content #{!important}

//selected option
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    &:hover
        @apply bg-newNeutral3 #{!important}
.mat-mdc-option .mat-pseudo-checkbox-minimal
    @apply hidden #{!important}
.mat-pseudo-checkbox
    @apply hidden w-5 h-5 rounded-sm m-0 border border-solid border-primarySecondary hover:border-secondaryMainLight #{!important}

.mat-pseudo-checkbox-checked
    @apply bg-secondaryMain border-0 hover:bg-indigo-600 #{!important}

.mat-mdc-option .mdc-list-item__primary-text
    @apply block m-0 w-full #{!important}
