@import "styles/index"

.positionChildElementsByHalves
    //each child will take half of the width minus 8px (for the gap) and on mobile will take the whole width
    @apply [&>*]:flex-50 xsm:[&>*]:max-w-[calc(50%-8px)]

//  The additional overlay prevents closing the Menu when we click outside. Material doesn't have this feature out of the box.
.menu-extra-backdrop
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 998

.tb-menu-panel .mat-mdc-menu-content
    padding: 0 !important

::-webkit-scrollbar
    @apply w-[0.5rem]

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover
    @apply bg-newNeutral3 rounded-lg
