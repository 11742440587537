.mat-mdc-dialog-container
    --mdc-dialog-container-shape: 16px !important

.mat-mdc-dialog-container
    .mdc-dialog__surface
         @apply shadow-none #{!important}

@media not all and (screen(md))
    .modal-flow-dialog
        @apply w-screen h-screen max-h-screen max-w-full #{!important}


