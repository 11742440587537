.display-2
    font-weight: 700
    font-size: 3rem
    line-height: 4.5rem

.display-1
    font-weight: 600
    font-size: 2.125rem
    line-height: 2.5rem

.headline
    font-weight: 600
    font-size: 1.5rem
    line-height: 2.5rem

.title
    font-weight: 600
    font-size: 1.25rem
    line-height: 1.875rem

.subheading-1
    font-weight: 400
    font-size: 1rem
    line-height: 1.5rem

.subheading-2
    font-weight: 600
    font-size: 1rem
    line-height: 1.5rem

.body-1
    font-weight: 400
    font-size: 0.875rem
    line-height: 1.25rem

.body-2
    font-weight: 600
    font-size: 0.875rem
    line-height: 1.25rem

.caption-1
    font-weight: 400
    font-size: 0.75rem
    line-height: 1rem

.caption-2
    font-weight: 600
    font-size: 0.75rem
    line-height: 1rem

