@tailwind base
@tailwind components
@tailwind utilities

@import "global"
@import "components"

@import "./theme/variables"
@import "./theme/shadows"
@import "./theme/typography"

@layer base
    body
        @apply text-primaryDark
        font-size: 16px
        font-family: var(--tb-font-family), sans-serif
