// Variables
.mat-mdc-checkbox
    &,
    &.tb-color-primary
        --tb-checkbox-color-label: theme('colors.newText')
        --tb-checkbox-color: theme('colors.newPrimaryLight')
        --tb-checkbox-color-hover: theme('colors.newPrimary')
        --tb-checkbox-color-checked: theme('colors.newPrimary')
        --tb-checkbox-color-checked-hover: theme('colors.newPrimaryDark')
        &.tb-indeterminate
            --tb-checkbox-color-hover: theme('colors.newPrimaryLight')
            --tb-checkbox-color-checked: theme('colors.newPrimaryLight')
            --tb-checkbox-color-checked-hover: theme('colors.newPrimary')


    &.tb-color-secondary
        --tb-checkbox-color-label: theme('colors.newText')
        --tb-checkbox-color: theme('colors.newSecondaryLight')
        --tb-checkbox-color-hover: theme('colors.newSecondary')
        --tb-checkbox-color-checked: theme('colors.newSecondary')
        --tb-checkbox-color-checked-hover: theme('colors.newSecondaryDark')
        &.tb-indeterminate
            --tb-checkbox-color-hover: theme('colors.newSecondaryLight')
            --tb-checkbox-color-checked: theme('colors.newSecondaryLight')
            --tb-checkbox-color-checked-hover: theme('colors.newSecondary')


// Rewrite mat checkbox
.mat-mdc-checkbox
    @apply rounded-[4px] flex #{!important}

    .mdc-checkbox, .mdc-checkbox__native-control, .mat-mdc-checkbox-touch-target
        @apply h-5 w-5 min-w-[20px] p-0 rounded-[4px] #{!important}

    .mdc-label
        color: var(--tb-checkbox-color-label)
        @apply whitespace-nowrap pl-2 #{!important}


    .mdc-checkbox__background
        border-color: var(--tb-checkbox-color) !important
        @apply h-5 w-5 rounded-[4px] border #{!important}
        @apply top-0 left-0 #{!important}

        & > svg
            @apply h-3 w-3 m-auto text-newNeutral1 #{!important}

        .mdc-checkbox__mixedmark
            width: 1em !important
            @apply border-newNeutral1 text-newNeutral1 #{!important}


    .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple
        @apply hidden #{!important}


    &:hover .mdc-checkbox__background
        border-color: var(--tb-checkbox-color-hover) !important

    &.mat-mdc-checkbox-checked
        @apply box-content #{!important}

        .mdc-checkbox__background
            background-color: var(--tb-checkbox-color-checked) !important
            border-color: var(--tb-checkbox-color-checked) !important

        &:hover
            .mdc-checkbox__background
                background-color: var(--tb-checkbox-color-checked-hover) !important
                border-color: var(--tb-checkbox-color-checked-hover) !important

    &.tb-indeterminate
        .mdc-checkbox__background
            background-color: var(--tb-checkbox-color-checked) !important
            border-color: var(--tb-checkbox-color-checked) !important

        &:hover
            .mdc-checkbox__background
                background-color: var(--tb-checkbox-color-checked-hover) !important
                border-color: var(--tb-checkbox-color-checked-hover) !important

// Small size
.mat-mdc-checkbox.small
    &,
    & .mdc-checkbox,
    & .mdc-checkbox__background,
    & .mdc-checkbox__native-control,
    & .mat-mdc-checkbox-touch-target
        @apply w-4 h-4 min-w-[16px] #{!important}

    & .mdc-checkbox__mixedmark
        width: .8em !important


// Disabled state
.mat-mdc-checkbox.mat-mdc-checkbox-disabled
    .mdc-checkbox__background
        @apply border-newNeutral4 #{!important}

    &.tb-indeterminate .mdc-checkbox__background
        @apply border-newNeutral4 bg-newNeutral4 #{!important}

    &.mat-mdc-checkbox-checked .mdc-checkbox__background
        @apply bg-newNeutral4 #{!important}

    .mdc-label
        @apply text-newNeutral4 #{!important}
