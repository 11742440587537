//TODO: remove?
.mat-expansion-panel
    @apply shadow-none #{!important}
    .mat-expansion-panel-header
        @apply p-0 h-auto #{!important}

.mat-expansion-panel.advancedOptions
    .mat-expansion-panel-header
        @apply bg-primaryBackground #{!important}
        &:hover
            .mat-expansion-panel-header-title
                @apply text-primaryDark #{!important}


.mat-content.mat-content-hide-toggle
    @apply m-0 #{!important}

.mat-expansion-panel-body
    @apply p-0 #{!important}
