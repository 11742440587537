@function HexToRGB($hex)
    @return red($hex), green($hex), blue($hex)
\:root

    // colors
    --tb-primary: #536DFE
    --tb-primary-dark: #3F51B5
    --tb-primary-light: #8C9EFF

    --tb-secondary: #FF6E40
    --tb-secondary-dark: #E64A19
    --tb-secondary-light: #FF9E80

    --tb-text: #06134A
    --tb-text-light: #7687C5

    --tb-text-inversed: #FFFFFF
    --tb-text-inversed-light: #9DA9D5

    --tb-dark-theme-primary-dark: #282A5F
    --tb-dark-theme-disabled: #434D8F

    --tb-neutral-4: #D1D6EC
    --tb-neutral-4-rgb: #{HexToRGB(#D1D6EC)}
    --tb-neutral-3: #F1F3FC
    --tb-neutral-2: #FAFAFA
    --tb-neutral-1: #FFFFFF

    --tb-red-50: #FFEBEE
    --tb-red-100: #FFCDD2
    --tb-red-200: #EF9A9A
    --tb-red-500: #F44336
    --tb-red-600: #E53935
    --tb-red-800: #C62828
    --tb-red-900: #B71C1C

    --tb-amber-50: #FFF8E1
    --tb-amber-100: #FFECB3

    --tb-blue-50: #E3F2FD
    --tb-blue-100: #BBDEFB

    // typography
    --tb-font-family: "System UI", "Segoe UI", "San Francisco", "Roboto", "Helvetica", "Arial", "Noto" , "Lato" , "Open Sans", "Montserrat", "Source Sans Pro", "Droid Sans", "IBM Plex" , sans-serif

    // remove usage of these colors
    --primary-light: #E8EAF6
    --primary-secondary: #9da9d5
    --primary-main: #282A5F
    --primary-dark: #06134A

    --secondary-light: #8C9EFF
    --secondary-main: #536DFE

    --contrast-light: #FF9E80
    --contrast-main: #FF6E40

    --background: #FAFAFA
