::-webkit-scrollbar
    width: 6px
    height: 6px
    background-color: transparent

::-webkit-scrollbar-thumb
    background-color: #BDBDBD
    border-radius: 9em
    box-shadow: none

::-webkit-scrollbar-thumb:hover
    background-color: #9E9E9E


::-webkit-scrollbar-button:vertical:start:decrement
    background: transparent


::-webkit-scrollbar-button:vertical:end:increment
    background: transparent


::-webkit-scrollbar-button:horizontal:start:decrement
    background: transparent


::-webkit-scrollbar-button:horizontal:end:increment
    background: transparent
