mat-button-toggle-group
    @apply rounded-lg h-[calc(1.5rem+2px)] overflow-visible #{!important}

.mat-button-toggle-group-appearance-standard:hover,
.mat-button-toggle-appearance-standard:hover
    @apply font-semibold bg-primaryLight #{!important}

.mat-button-toggle-appearance-standard.mat-button-toggle-disabled:hover
    @apply bg-white #{!important}
.mat-button-toggle-appearance-standard.mat-button-toggle-disabled.mat-button-toggle-checked:hover
    @apply bg-primarySecondary #{!important}

.mat-button-toggle-appearance-standard
    @apply h-6 w-[5rem] rounded-lg
    &:not(.mat-button-toggle-checked):first-child
        @apply rounded-r-none
    &:not(.mat-button-toggle-checked):last-child
        @apply rounded-l-none

.mat-button-toggle-appearance-standard.mat-button-toggle-checked
    @apply text-newNeutral1  bg-newSecondaryLight h-8 flex items-center justify-center mt-[-0.25rem]  #{!important}

.andOrToggle .mat-button-toggle-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked
    @apply bg-secondaryMain #{!important}

.mat-button-toggle-appearance-standard.mat-button-toggle-disabled
    @apply text-primarySecondary #{!important}

.andOrToggle .mat-button-toggle-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked.mat-button-toggle-disabled,
.mat-button-toggle-appearance-standard.mat-button-toggle-checked.mat-button-toggle-disabled
    @apply text-white bg-primarySecondary #{!important}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content
    @apply py-0 px-3 w-[5rem] h-6 flex subheading-1 items-center justify-center #{!important}

.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard
    @apply border-none #{!important}

.mat-button-toggle-focus-overlay
    @apply hidden

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard
    @apply border-primaryLight #{!important}
